import React from "react"
import { createContext, useState, useContext } from "react"

const GlobalContext = createContext({
  showCompanies: false,
  setShowCompanies: () => null,
})

const GlobalProvider = ({ children }) => {
  const [showCompanies, setShowCompanies] = useState(false)

  return (
    <GlobalContext.Provider
      value={{
        showCompanies,
        setShowCompanies,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

const useGlobalContext = () => {
  return useContext(GlobalContext)
}

export { GlobalContext, GlobalProvider, useGlobalContext }
